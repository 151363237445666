import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HeaderPlayer from "./header-player"

export default function Header() {
  const { allStation } = useStaticQuery(
    graphql`
      query {
        allStation {
          edges {
            node {
              ...Station
            }
          }
        }
      }
    `
  )
  const station =
    allStation && allStation.edges && allStation.edges[0]
      ? allStation.edges[0].node
      : null
  const image = station ? getImage(station.featuredImg) : null
  // console.log(allStation)
  return (
    <header
      className={
        " px-2 xl:px-8 pb-4 block xl:flex justify-between items-center w-100 bg-white gap-4 mt-4 xl:mt-4"
      }
    >
      <div className={"w-full xl:w-1/3 text-center xl:text-left py-8"}>
        {/* {process.env.GATSBY_TAGLINE && (
          <p className={"font-light xl:pr-4 xxl:pr-72"}>
            {process.env.GATSBY_TAGLINE && process.env.GATSBY_TAGLINE}
            {process.env.GATSBY_TAGLINE_LINK_TEXT &&
              process.env.GATSBY_TAGLINE_LINK && (
                <a
                  href={process.env.GATSBY_TAGLINE_LINK}
                  className={"text-brand font-bold"}
                  target="_blank"
                >
                  {process.env.GATSBY_TAGLINE_LINK_TEXT}
                </a>
              )}
          </p>
        )} */}
      </div>
      <div className={"w-full xl:w-1/3 text-center site-logo px-8"}>
        <Link to="/">
          {image && <GatsbyImage image={image} alt={station.name} />}
        </Link>
        {process.env.GATSBY_TAGLINE && (
          <p className={"font-light text-center mt-5"}>
            {process.env.GATSBY_TAGLINE && process.env.GATSBY_TAGLINE}
            {process.env.GATSBY_TAGLINE_LINK_TEXT &&
              process.env.GATSBY_TAGLINE_LINK && (
                <a
                  href={process.env.GATSBY_TAGLINE_LINK}
                  className={"text-brand font-bold"}
                  target="_blank"
                >
                  {process.env.GATSBY_TAGLINE_LINK_TEXT}
                </a>
              )}
          </p>
        )}
      </div>
      <div className={"hidden xl:flex justify-end xl:w-1/3"}>
   
      </div>
    </header>
  )
}
