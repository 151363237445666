import React, { useContext } from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import { StationContext } from "../../context/station-context"
import PlaylistItem from "./playlist-item"
import {
  PlayIcon,
  PauseIcon,
  RewindIcon,
  FastForwardIcon,
  XIcon,
} from "@heroicons/react/solid"
import BarWithTimeOnHover from "./time-bar"
import moment from "moment"

export default function SidebarContent({}) {
  const {
    setLiveStream,
    updateTime,
    currentTrack,
    playlist,
    setMobileSidebar,
    isPlaying,
    setIsPlaying,
    onAir,
    setSidebar,
    durationSeconds,
    streamProgress,
    seek,
    streamPercent,
  } = useContext(StationContext)
  return (
    <>
      <button
        className={"xl:hidden p-4 absolute top-0 right-0 "}
        onClick={() => setMobileSidebar(false)}
      >
        <XIcon className="text-white h=8 w-9" />
      </button>

      {/* <button
        className={"hidden xl:flex p-4 absolute top-0 right-0 z-10"}
        onClick={() => setSidebar(false)}
      >
        <XIcon className="text-white h-4 w-4 xxl:h-8 xxl:w-9" />
      </button> */}
      {onAir && (
        <button
          className={
            "bg-gray-500 px-2 xl:px-8 py-8 mb-4 w-full flex gap-4 flex items-center"
          }
          onClick={() => setLiveStream()}
        >
          <PlayIcon className={"text-gray-200 w-12 h-12"} />
          <span className={"text-left"}>
            On Air
            <br /> {onAir.program.name}
          </span>
        </button>
      )}

      {currentTrack && currentTrack.url ? (
        <div className={"w-full py-1 px-2 xl:px-8 mb-4"}>
          <button
            className={
              "mx-auto w-full bg-white text-brand hover:bg-brand hover:text-white font-bold py-2 pr-4 px-2 rounded-full flex items-center gap-2"
            }
            onClick={() => {
              setIsPlaying(!isPlaying)
            }}
          >
            {isPlaying === true ? (
              <PauseIcon className={"w-12 h-12"} />
            ) : (
              <PlayIcon className={"w-12 h-12"} />
            )}
            <div className="text-left">
              <p>{currentTrack.node.program.name}</p>
              <p className="text-xs font-light">
                {currentTrack && currentTrack.start}
              </p>
            </div>
          </button>
        </div>
      ) : (
        <div className={"w-full py-1 px-2 xl:px-8 mb-4"}>
          <div
            className={
              "mx-auto w-full bg-white text-brand hover:bg-brand text-white font-bold py-2 pr-4 px-2 rounded-full flex items-center gap-2"
            }
          >
            Nothing Selected
          </div>
        </div>
      )}
      {currentTrack && currentTrack.isLive !== true && (
        <div className={"flex gap-2 items-center mb-4 px-2 xl:px-8"}>
          <div>
            <button className={""} onClick={() => seek(-15)}>
              <RewindIcon className={"text-gray-300 h-8 xl:h-12 w-8 xl:w-12"} />
            </button>
          </div>
          <div className={"flex-grow"}>
            <div className="mrc-progress" onClick={e => updateTime(e)}>
              <div
                className="seeker"
                style={{ width: `${streamPercent}%` }}
              ></div>
            </div>
            <div className="flex justify-between mt-2 text-gray-300">
              <div>
                {moment("2015-01-01")
                  .startOf("day")
                  .seconds(Math.floor(streamProgress))
                  .format("H:mm:ss")}
              </div>
              {durationSeconds && (
                <div>
                  -
                  {moment("2015-01-01")
                    .startOf("day")
                    .seconds(Math.floor(durationSeconds - streamProgress))
                    .format("H:mm:ss")}
                </div>
              )}
            </div>
          </div>
          <div>
            <button className={" "} onClick={() => seek(15)}>
              <FastForwardIcon
                className={"text-gray-300 h-8 xl:h-12 w-8 xl:w-12"}
              />
            </button>
          </div>
        </div>
      )}
      <div className="w-full text-center">
        <h5 className="w-full text-white text-sm font-semibold mb-4 pb-4 border-bottom-1 border-white">
          YOUR QUEUE
        </h5>
      </div>
      {playlist &&
        playlist.map((item, i) => {
          return <PlaylistItem key={`playlist--${i}`} item={item} index={i} />
        })}
      {playlist.length === 0 && (
        <div className={"px-4 lg:px-8 text-center text-sm"}>
          <p className="font-light mb-4">There is nothing in your queue.</p>{" "}
          <p>
            Check the{" "}
            <Link to={"/ondemand"} className={"font-semibold text-blue-200"}>
              Ondemand Page
            </Link>{" "}
            to listen back to our programs.
          </p>
        </div>
      )}
    </>
  )
}
