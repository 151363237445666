import React, { useContext } from "react"
import { Transition } from "@headlessui/react"
import { StationContext } from "../context/station-context"
import SidebarContent from "./player/sidebar-content"
import { XIcon } from "@heroicons/react/solid"

export default function SiteSideBar({}) {
  const { mobileSidebar, sidebar, setMobileSidebar } =
    useContext(StationContext)
  return (
    <>
      <Transition
        show={mobileSidebar}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        className={
          "xl:hidden z-30 max-w-screen fixed left-0 right-0 top-0 bottom-0 sidebar h-screen static overflow-y-scroll overflow-x-hidden h-screen xl:static text-white bg-gray-700  "
        }
      >
        <SidebarContent />
      </Transition>
      <Transition
        show={sidebar}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className={
          "hidden max-w-screen xl:block fixed left-0 top-0 right-0 bottom-0 sidebar h-screen static overflow-y-scroll overflow-x-hidden h-screen xl:static text-white bg-gray-700 "
        }
      >
        <SidebarContent />
      </Transition>
    </>
  )
}
