import React, { useContext } from "react"
import { StationContext } from "../../context/station-context"
import { PauseIcon, PlayIcon } from "@heroicons/react/solid"

export default function MobilePlauerIndicator() {
  const { currentTrack, setMobileSidebar, isPlaying } =
    useContext(StationContext)

  return (
    <div
      className={
        "w-full fixed top-0 right-0 left-0 z-20  text-left justify-start flex gap-4 py-4 px-4 text-lg xl:hidden text-left text-gray-200 bg-gray-700 items-center  "
      }
      onClick={() => setMobileSidebar(true)}
    >
      {isPlaying === true ? (
        <PauseIcon className="h-4 w-4" onClick={() => setMobileSidebar(true)} />
      ) : (
        <PlayIcon className="h-4 w-4" onClick={() => setMobileSidebar(true)} />
      )}
      {currentTrack && currentTrack.node.program.name}
    </div>
  )
}
