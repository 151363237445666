import React, { useState, useContext } from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import { XIcon, MenuIcon } from "@heroicons/react/solid"
import { StationContext } from "../../context/station-context"
import NavBarItem from "./nav-bar-item"
// import ReactPlayer from "react-player"

function NavBar() {
  const [isOpen, setIsOpen] = useState(false)
  const { setSidebar, playlist, selectedIndex } = useContext(StationContext)

  return (
    <div>
      <nav className="bg-white-800 mx-auto mb-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex w-100 items-center justify-around h-16 mx-auto">
            <div className="flex w-100 items-center">
              <div className="hidden md:block">
                <div className="mx-auto flex items-baseline space-x-4">
                  <Link
                    to="/"
                    className=" text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Home
                  </Link>

                  <Link
                    to="/programs"
                    className="text-gray-700  hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Programs
                  </Link>

                  <Link
                    to="/ondemand"
                    className="text-gray-700  hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Ondemand
                  </Link>

                  <Link
                    to="/schedule"
                    className="text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Schedule
                  </Link>

                  <div className={"relative"}>
                    
                    <NavBarItem />
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-blue-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <MenuIcon className={"h-6 w-6"} />
                ) : (
                  <XIcon className={"h-6 w-6"} />
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {ref => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link
                  to="/"
                  className="block  text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </Link>

                <Link
                  to="/programs"
                  className="block text-gray-700  hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Programs
                </Link>

                <Link
                  to="/ondemand"
                  className="block text-gray-700  hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Ondemand
                </Link>

                <Link
                  to="/schedule"
                  className="block text-gray-700  hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Schedule
                </Link>

                {/* <Link
                  to="/"
                  className="block xt-gray-700 hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Schedule
                </Link> */}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  )
}

export default NavBar
