import React from "react"

export default function Footer() {
  return (
    <footer className="p-16 lg:pt-32 p-16 lg:pb-32 text-white bg-brand">
      <div className="xl:max-w-7xl mx-auto px-4 sm:px-6 lg:px-4 gap-4">
        <div className={"grid grid-cols-12"}>
          <div className="col-span-12 lg:col-span-4">  </div>
          <div className="col-span-12 lg:col-span-4 text-center"> © {new Date().getFullYear()} <span className={'uppercase'}>{process.env.GATSBY_SITE_NAME}</span></div>
          <div className="col-span-12 lg:col-span-4  t">  </div>
        </div>
      </div>
    </footer>
  )
}
