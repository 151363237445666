import React from "react"
import { Link } from "gatsby"
import { Transition } from "@headlessui/react"


export default function NavBarItem({ title }) {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className="text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md text-sm font-medium"
      >
        About
      </button>
      <Transition
        show={open}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={"absolute top-full bottom-0 left-0 block bg-white"}
      >
        <Link
          to="/about-us"
          className="block text-gray-700 hover:text-blue-500 bg-white px-3 py-2 rounded-md text-sm font-medium w-full whitespace-nowrap"
        >
          About Us
        </Link>
        <Link
          to="/contact-us"
          className="block text-gray-700 hover:text-blue-500 bg-white px-3 py-2 rounded-md text-sm font-medium w-full whitespace-nowrap"
        >
          Contact Us
        </Link>
      </Transition>
    </>
  )
}
