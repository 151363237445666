import React, { useContext } from "react"
import { PlayIcon, PauseIcon } from "@heroicons/react/solid"
import { StationContext } from "../../context/station-context"
import DropdownRender from "./dropdown"

export default function PlaylistItem({ item, index }) {
  const { setActiveIndex, isPlaying, setIsPlaying, currentTrack } =
    useContext(StationContext)

  return (
    <div className={"px-2 xl:px-8 flex"}>
      <button
        className={
          "w-full mb-2 p-4 text-left flex text-white items-center gap-4"
        }
        onClick={() => {
          console.log(currentTrack, item.node.original_id)
          if (isPlaying === true && currentTrack.node.original_id === item.node.original_id) {
            setIsPlaying(false)
          } else {
            if (currentTrack && currentTrack.node.original_id === item.node.original_id) {
              setIsPlaying(true)
            } else {
              setActiveIndex(index)
              setIsPlaying(true)
            }
          }

          //   setIsPlaying(true)
        }}
      >
        {currentTrack && isPlaying === true && currentTrack.node.original_id === item.node.original_id ? (
          <PauseIcon className={"h-8 w-8"} />
        ) : (
          <PlayIcon className={"h-8 w-8"} />
        )}
        <div>
          <p className={"w-full font-bold clear-both"}>
            {item.node.program.name}
          </p>
          <p className={"w-full text-xs font-extralight"}>
            {item.start && item.start}
          </p>
        </div>
      </button>
      <DropdownRender index={index} episode={item} />
    </div>
  )
}
