import * as React from "react"
import { Link } from "gatsby"
import SiteSideBar from "./site-side-bar"
import NavBar from "./global/nav-bar"
import MobilePlauerIndicator from "./global/mobile-player-indicator"
import Header from "./global/header"
import Footer from "./global/footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}> 
      <div className={"lg:flex w-full relative"}>
        <div
          className={
            " h-screen lg:static lg:overflow-y-scroll lg:overflow-x-hidden lg:flex-grow"
          }
        >
          <MobilePlauerIndicator />
          <Header />
          <NavBar />
          <main className={" xl:max-w-7xl mx-auto px-4 sm:px-6 lg:px-4 "}>
            {children}
          </main>

          <Footer />
        </div>
        <SiteSideBar />
      </div>
    </div>
  )
}

export default Layout
