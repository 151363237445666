import React from "react"
import { createPopper } from "@popperjs/core"
import { DotsVerticalIcon } from "@heroicons/react/solid"
import { StationContext } from "../../context/station-context"

const Dropdown = ({ color, index, episode }) => {
  const { setActiveIndex, removeItemById } = React.useContext(StationContext)
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
  const btnDropdownRef = React.createRef()
  const popoverDropdownRef = React.createRef()
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    })
    setDropdownPopoverShow(true)
  }
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }
  // bg colors
  let bgColor
  color === "white"
    ? (bgColor = "bg-blueGray-700")
    : (bgColor = "bg-" + color + "-500")
  return (
    <>
      <div className="flex flex-wrap items-center">
        <div className="">
          <div className="relative inline-flex align-middle w-full">
            <button
              className={
                "text-gray-400 text-left uppercase  px-1 py-0 rounded shadow  outline-none focus:outline-none   ease-linear transition-all duration-150 bg-trqansparent "
              }
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover()
              }}
            >
              <DotsVerticalIcon className={"h-4 w-4"} />
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                (color === "white" ? "bg-white " : bgColor + " ") +
                "text-black z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1text-left"
              }
              style={{ minWidth: "12rem" }}
            >
              <button
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-left" +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={() => {
                  setActiveIndex(index);
                  setDropdownPopoverShow(false)
                }}
              >
                Play Now
              </button>
              {/* <button
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-left" +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={e => e.preventDefault()}
              >
                Play Next
              </button> */}
              <div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25" />
              <button
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-left" +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={() => {
                  removeItemById(episode.node.original_id)
                  setDropdownPopoverShow(false)
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function DropdownRender({ index, episode }) {
  return (
    <>
      <Dropdown color="white" index={index} episode={episode} />
    </>
  )
}
